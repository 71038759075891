$cookie-bg: #223037;
$cookie-txt: #ffffff;
$cookie-button-bg: transparent;
$cookie-button-text: #ffffff;
$cookie-button-decline-bg: transparent;
$cookie-button-decline-text: #ffffff;

.cookienotice {
    width: 100%;
    left: 0;
    right: 0;
    background-color: $cookie-bg;
    position: fixed;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px;
    @media only screen and (max-width: 767px) {
        padding: 10px 20px;
    }
    text-align: left;
    color: $cookie-txt;
    z-index: 9999999;
    &--top {
        top: 0;
    }
    &--bottom {
        bottom: 0;
    }
    strong {
        color: $cookie-txt;
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 10px;
    }
    p a {
        color: $cookie-txt;
        text-decoration: underline;
    }
    p,
    .cookienotice__button {
        font-size: 16px;
        margin: 0;
        color: $cookie-txt;
        line-height: 1.5;
        @media only screen and (max-width: 767px) {
            font-size: 13px;
        }
    }
    .cookienotice__button {
        cursor: pointer;
        text-decoration: none;
        margin-top: 20px;
        &--decline {
            color: lighten($cookie-txt, 20%);
        }
    }
    .cookienotice__button--accept {
        background-color: $cookie-button-bg;
        color: $cookie-button-text;
        font-weight: 700;
        display: inline-block;
        border: 2px solid rgba(255, 255, 255, 0.1);
        padding: 10px 30px;
    }
    .cookienotice__button--decline {
        background-color: $cookie-button-decline-bg;
        color: $cookie-button-decline-text;
        font-weight: 700;
        display: inline-block;
        padding: 10px 30px 10px 0;
    }
    &.hide {
        display: none;
    }
}

.cookiesettings {
    @media only screen and (min-width: 1024px) {
        display: flex;
        font-size: 15px;
    }
    p,
    ul li {
        font-size: 15px;
    }
    &__card {
        cursor: pointer;
        padding: 20px;
        border: 2px solid #fff;
        @media only screen and (min-width: 1024px) {
            width: 50%;
            padding: 30px;
            flex: 1;
        }
        &.active {
            border: 2px solid $palm-green;
        }
    }
    &__button {
        background-color: $palm-green;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
        display: inline-block;
        padding: 10px 30px;
        &--inactive {
            background-color: #b3b3b3;
            cursor: not-allowed;
            color: #464646;
            font-weight: 700;
            display: inline-block;
            padding: 10px 30px;
        }
    }
}
