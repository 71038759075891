
.icon-search:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-mail-alt:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-left-open:before { content: '\e805'; } /* '' */
.icon-right-open:before { content: '\e806'; } /* '' */
.icon-youtube-play:before { content: '\e807'; } /* '' */
.icon-book-open:before { content: '\e808'; } /* '' */
.icon-attention-alt:before { content: '\e809'; } /* '' */
.icon-plus-circled:before { content: '\e80a'; } /* '' */
.icon-minus-circled:before { content: '\e80b'; } /* '' */
.icon-help-circled:before { content: '\e80c'; } /* '' */
.icon-info-circled:before { content: '\e80d'; } /* '' */
.icon-calendar:before { content: '\e80e'; } /* '' */
.icon-down-dir:before { content: '\e80f'; } /* '' */
.icon-star:before { content: '\e810'; } /* '' */
.icon-star-half-alt:before { content: '\e811'; } /* '' */
.icon-star-empty:before { content: '\e812'; } /* '' */
.icon-linkedin:before { content: '\e813'; } /* '' */
.icon-pinterest:before { content: '\e814'; } /* '' */
.icon-github-circled:before { content: '\e815'; } /* '' */
.icon-flash:before { content: '\e816'; } /* '' */
.icon-link:before { content: '\e817'; } /* '' */
.icon-rss:before { content: '\f09e'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
