.slick-dots {
    text-align: center;
    bottom: -39px;
}
.slick-prev,
.slick-next {
    width: 40px;
    height: 40px;
    background: $primary;
    border-radius: 0;
    z-index: 1;
}
.projecten-slider {
    padding-bottom: 32px;
}
@media screen and (min-width: $medium) {
    .slick-dots {
        text-align: left;
        bottom: -75px;
    }
    .projecten-slider {
        padding-bottom: 108px;
        .slick-list {

        }
    }
}
@media screen and (max-width: $medium) {
    .product-slider {
        &__width {
            margin: 0 !important;
            width: 100% !important;
        }
    }
}


#instafeed {
    .slick-slide {
        margin: 0 23px;
    }
    .sliderItem {
        width: 270px;
        height: 270px;

        &__bg {
            height: 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}
