.matrix,
.matrix-element,
.cookiesettings {
  ul,
  ol {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
  }

  ul li {
      position: relative;
      padding-left: 2em;

      &:before {
        font-size: 32px;
        content: '';
        background-image: url('/img/site/vinkje-primary.svg');
        background-repeat: no-repeat;
        height: 9px;
        width: 14px;
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        bottom: 0;
        margin: auto;
        color: $blue;
    }
  }


  ol {
      counter-reset: list;
      > li {
        position: relative;
        padding-left: 2em;
        &:before {
            counter-increment: list;
            content: counter(list) '.';
            display: inline-block;
            color: $blue;
            font-weight: 500;
            position: absolute;
            left: 0;
        }
        &:nth-child(10n) ~ li:before,
        &:nth-child(10n):before {
          content: counter(list);
        }
      }
  }

  ul ol,
  ol ul {
      padding-top: 0;
  }
}
.opsomming {
    a {
        margin-bottom: .5rem;
        &:last-child {
            margin-bottom: 0;
        }
        span {
            font-size: 10px;
            line-height: 17px;
            border-radius: 16px;
            padding-top: 5.5px;
            padding-bottom: 5.5px;
        }
    }
}
@media screen and (min-width: $medium) {
    .opsomming {
        a {
            span {
                padding-top: 8px;
                padding-bottom: 8px;
                font-size: 13px;
                line-height: 20px;
                border-radius: 20px;

            }
        }
    }
}
