.breadcrumbs {
    li {
        font-size: 11px;
        line-height: 20px;
        color: $grey;
        list-style-type: none;
        display: inline-block;
        &:last-child {
            background-image: transparent;
        }
        a {
            font-size: 11px;
            line-height: 20px;
            color: $grey;
            display: inline-block;
        }
    }
    img {
        width: 6px;
    }
}
.share {
    font-size: 11px;
}
