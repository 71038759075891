
.white p {
  color: #fff;
}

.content-over-image {
  overflow: hidden;

  &:hover .bg-overlay {
    background-color: rgba(18, 18, 20, .4);
  }
  &:hover img {
    transform: rotate(1.5deg) scale(1.05);
  }
  img {
    transition: all .3s;
  }
  .bg-overlay {
    transition: all .3s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    background-color: rgba(18, 18, 20, 0.5);
  }
  .text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    margin: auto;
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.maatwerk-bg {
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.matrix .content-over-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @media only screen and (min-width: $medium) {
    height: 340px;
  }
}


// Cases part:

.over {
  &__blok {
    position: relative;
    &:before {
      content: ' ';
      background-color: $cases-grey;
      top: 0;
      z-index: -1;
      width: 300%;
      bottom: -100px;
      left: -100px;
      right: -100px;
      @media only screen and (min-width: 768px) {
        bottom: -200px;
        left: -200%;
        right: 0;
      }
      position: absolute;
    }
  }
  &__cases {
    .background {
      height: 440px;
      width: 100%;
      max-width: 100%;
      @media only screen and (min-width: 768px) {
        width: 380px;
      }
    }
    .slick-next,
    .slick-prev {
      bottom: inherit;
      top: -40px;
      margin-right: 0 !important;
    }
    .slick-next {
      background-image: url('/img/site/pijltje-rechts-primary.svg');
      background-color: inherit;
      border-left: 1px solid #223037;
      right: 0;
    }
    .slick-prev {
      background-image: url('/img/site/pijltje-links-primary.svg');
      background-color: inherit;
      right: 40px;
    }
  }
}



.werkwijze {
  &__blok {
    position: relative;
    &:before {
      content: ' ';
      background-color: $cases-grey;
      top: 0;
      z-index: -1;
      width: 300%;
      left: -100px;
      right: -100px;
      bottom: -100px;
      @media only screen and (min-width: 768px) {
        bottom: -200px;
        right: -200%;
        left: 0;
      }
      position: absolute;
    }
  }
  .slider {
    &__item {
      text-align: left;
      max-width: 100%;
      @media only screen and (min-width: 768px) {
        max-width: 380px;
      }
    }
    .slick-slide {
      opacity: 0.6;
      transition: all ease 0.4s;
      svg g {
        fill: #454545;
        stroke: #454545;
        transition: all ease 0.2s;
      }
    }
    .slick-current {
      opacity: 1;
      svg g{
        fill: $cases-orange;
        stroke: $cases-orange;
      }
    }
    .slick-next,
    .slick-prev {
      bottom: inherit;
      top: -40px;
      margin-right: 10px !important;
    }
    .slick-next {
      background-image: url('/img/site/pijltje-rechts-primary.svg');
      background-color: inherit;
      border-left: 1px solid #223037;
      right: 0;
    }
    .slick-prev {
      background-image: url('/img/site/pijltje-links-primary.svg');
      background-color: inherit;
      right: 40px;
    }
    .slick-dots {
      display: none;
      @media only screen and (min-width: 768px){
        display: inline;
        counter-reset: dots;
        top: -40px !important;
        bottom: inherit;
        li {
          margin-right: 40px;
          button:before {
            background-color: inherit !important;
          }
          color: #223037;
          opacity: 0.4;
          font-weight: 500;
          &.slick-active {
            opacity: 1;
          }
          &:before {
            counter-increment: dots;
            content: counter(dots);
          }
        }
      }
    }
  }
}


.svg-cases-orange {
  svg {
    fill: $cases-orange;
    stroke: $cases-orange;
    g, path {
      fill: $cases-orange;
      stroke: $cases-orange;
    }
  }
}

.svg-cases-white {
  svg {
    fill: $white;
    stroke: $white;
    g, path {
      fill: $white;
      stroke: $white;
    }
  }
}

.producten {
  &__afbeelding {
    height: 240px;
    @media only screen and (min-width: 768px) {
      height: 480px;
    }
    z-index: -1;
    position: relative;
  }
  &__blok {
    position: relative;
    &:before {
      content: ' ';
      background-color: $cases-grey;
      top: 0;
      z-index: -1;
      width: 300%;
      right: -100px;
      left: -100px;
      bottom: -100px;
      @media only screen and (min-width: 768px) {
        bottom: -200px;
        left: -200%;
        right: 0;
      }
      position: absolute;

    }
  }
  &__slider {
    .slick-next,
    .slick-prev {
      bottom: inherit;
      top: -40px;
      margin-right: 10px !important;
    }
    .slick-next {
      background-image: url('/img/site/pijltje-rechts-primary.svg');
      background-color: inherit;
      border-left: 1px solid #223037;
      right: 0;
    }
    .slick-prev {
      background-image: url('/img/site/pijltje-links-primary.svg');
      background-color: inherit;
      right: 40px;
    }
    &--slide {
      max-width: 380px;
      &.slick-slide {
        height: auto;
        display: flex !important;
      }
    }
  }
  .slick-track {
    height: auto;
    display: flex;
  }

}


