.nav-toggle {
    width: 16px;
    height: 12px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $primary;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2) {
            top: 5px;
            width: 50%;
        }
    }
}

/* Icon 3 */

#nav-icon3 span:nth-child(3) {
    top: 18px;
}

.nav-toggle {
    span:nth-child(4) {
        top: 10px;
        width: 75%;
    }
    &.open span {
        &:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
        &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 5px;
            width: 100%;
        }
        &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 5px;
            width: 100%;
        }
        &:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
}
