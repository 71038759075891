.product-slider {
    &__product {
        background-image: url('/img/site/product/hotbox.jpg');
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 100%;
        position: relative;
        width: 100%;
    }
    &__item {
    }

    &__title {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.product-specs {
    &__specificaties {
        .less {
            height: calc(290px - 43px);
            overflow: hidden;
            &.more {
                height: auto;
                overflow: auto;
            }
        }
    }
    &__bar {
        border: 1px solid $grey-opacity;
        border-left: 0;
        border-right: 0;
    }
    .more-specs {
        margin-top: -47px;
        padding-top: 47px;
    }
}
@media screen and (min-width: $medium) {
    .producten-wrapper {
        margin-top: -67px;
    }
}
