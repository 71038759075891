.eric {
    &:before {
        content: '';
        display: block;
        position: absolute;
        background-image: url('/img/site/eric-label.svg');
        width: 61px;
        height: 58px;
        background-size: 61px 58px;
        left: -53px;
    }
}
