.sidebar {
    a {
        h5 {
            color: $grey;
        }
        &.active {
            h5 {
                color: $primary;
            }
        }
    }
    .filters {
        border-bottom: 1px solid $grey-opacity;
        background-image: url("/img/site/pijl-beneden-grey-dark.svg");
        background-position: top 23.5px right;
        &.open {
            background-image: url("/img/site/pijl-rechts-grey-dark.svg");
        }
        &:last-of-type {
            border-bottom: 0;
        }
    }
}
