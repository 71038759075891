h1,
h2,
h3,
h4,
h5 {
    font-weight: 700;
    font-family: 'Merriweather', serif;
    color: $primary;
}
h6 {
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: $primary;
    text-transform: uppercase;
}
h1 {
    font-size: 18px;
    line-height: 31px;
    margin-bottom: .5rem;
}
h2 {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 1.5rem;
}
h3 {
    font-size: 14px;
    line-height: 24px;
}
h4 {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 1rem;
}
h5 {
    font-size: 11px;
    line-height: 19px;
}
h6 {
    font-size: 10px;
    line-height: 17px;
    span {
        font-size: 10px;
        line-height: 17px;
    }
}

p,
a,
li,
ol,
span {
    font-size: 13px;
    font-weight: 300;
    line-height: 23px;
    color: $grey-dark;
    &.breadcrumbs {
        font-size: 10px;
        line-height: 18px;
        color: $grey;
        padding-right: 22px;
        background-image: url('/img/site/pijl-rechts-grey.svg');
        background-repeat: no-repeat;
        background-position: center right;
        &:last-child {
            background-image: none;
        }
    }
}
a {
    text-decoration: none;
}
.account-informatie {
    span {
        font-size: 10px;
    }
}
@media screen and (min-width: $medium) {
    h1 {
        font-size: 26px;
        line-height: 44px;
    }
    h2 {
        font-size: 22px;
        line-height: 37px;
        margin-bottom: 2.5rem;
    }
    h3 {
        font-size: 19px;
        line-height: 32px;
    }
    h4 {
        font-size: 16px;
        line-height: 27px;
    }
    h5 {
        font-size: 14px;
        line-height: 24px;
    }
    h6 {
        font-size: 12px;
        line-height: 20px;
    }

    p,
    a,
    li,
    ol,
    span {
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        line-height: 27px;
        &.breadcrumbs {
            font-size: 11px;
            line-height: 20px;
        }
    }
}
p {
    a {
        color: $primary;
    }
}
@media screen and (min-width: 768px) {
    p {
        font-size: 13px;
    }
}


.font-fifteen {
    font-size: 15px;
}
.font-twelve {
    font-size: 12px;
}
.font-extrabold {
    font-weight: 500 !important;
}

