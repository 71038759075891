// Navigation
body {
    &.pop-up__open {
        overflow: hidden;
        height: 100vh !important;
    }
}

nav {
    li,
    a {
        text-transform: uppercase;
        font-size: 10px;
        line-height: 17px;
        color: $primary;
        font-weight: 400;
    }
    .menu-open,
    .menu-sluit {
        margin-top: -2px;
    }

    .mob-language-switcher {
        display: block;
    }

    ul {
        &.main-menu {
            display: none;
            &.open {
                display: block;
            }
            li {
                a {
                    color: $white;
                }
                &.has-children {
                    background-image: url('/img/site/pijl-rechts-wit.svg');
                    background-repeat: no-repeat;
                    background-position: center right 0;
                    .open {
                        width: 100%;
                        font-size: 13px;
                        font-family: 'Merriweather';
                        text-align: center;
                        display: block;
                        margin-bottom: 1.5rem;
                    }
                    ul {
                        // display: none;
                        .nav-item {
                            .bg-overlay {
                                background: linear-gradient(0deg, #3E3E3E 0%, rgba(43,33,33,0) 100%);
                                background-color: rgba(18, 18, 20, .3);
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                transition: all .3s;
                            }
                            &:hover .bg-overlay {
                                background: linear-gradient(0deg, #3E3E3E 0%, rgba(43,33,33,0) 70%);
                                background-color: rgba(18, 18, 20, .0);
                            }
                            &__background {
                                background-image: url('/img/site/nieuws.png');
                                background-size: cover;
                                background-position: center center;
                                padding: 48px 40px;
                                &--all {
                                    background-image: none;
                                    border: 1px solid $white;
                                    padding: 48px 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.mobile-menu {
            display: block;
        }
    }
    .top-nav {
        li {
            border-radius: 2px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            &.has-children {
                display: none;
            }
            a {
                span {
                    font-weight: 400;
                }
                .quantity {
                    line-height: 16px;
                    top: -8px;
                    right: -8px;
                }
            }
        }
    }
}
.top-nav {
    .has-children {
        &:hover {
            ul {
                &:after {
                    display: none;
                }
            }
        }
    }
}
@media screen and (min-width: $medium) {

    .mob-language-switcher {
        display: none!important;
    }

    body {
        height: auto !important;
    }
    nav {
        ul {
            &.main-menu {
                height: auto;
                display: block;
                li {
                    a {
                        color: $primary;
                    }
                }
                .has-children {
                    display: inline-block;
                }
            }
            &.mobile-menu {
                display: none;
            }
            li,
            a,
            span {
                font-size: 12px;
                line-height: 20px;
            }
        }
        li {
            &.has-children {
                background-repeat: no-repeat;
                background-image: url('/img/site/pijl-beneden-primary.svg') !important;
                background-position: center right 16px !important;
                padding-right: 32px;
                ul {
                    top: 75px;
                    transition: all .3s;
                    opacity: 0;
                    visibility: hidden;
                }
                &:hover {
                    .languages {
                        top: 60px !important;
                    }
                    ul {
                        top: 52px;
                        opacity: 1;
                        visibility: visible;
                        z-index: 9999;
                        &:after {
                            top: 0;
                            margin-top: -8px;
                            position: absolute;
                            display: block;
                            content: '';
                            width: 0;
                            height: 0;
                            border-left: 16px solid transparent;
                            border-right: 16px solid transparent;
                            border-bottom: 8px solid $primary;
                        }
                    }
                }
            }
        }
        .top-nav {
            li {
                height: 52px;
                line-height: 52px;
                width: auto;
                &.has-children {
                    display: block;
                }
                a,
                span {
                    line-height: 52px;
                    .quantity {
                        top: 10px;
                        right: 8px;
                    }
                }
                span {
                    display: inherit;
                }
            }
        }
    }
}
@media screen and (max-width: $medium) {
    body {
        &.nav-open {
            overflow: hidden;
        }
    }
    .main-menu {
        min-height: calc(100vh - 115px);
        .has-children {
            display: block;
            ul {
                display: none;
            }
            &.open {
                a {
                    font-size: 13px;
                    width: 100%;
                    font-family: 'Merriweather';
                    text-align: center;
                    display: block;
                    margin-bottom: 1.5rem;
                    font-weight: 700;
                    background-image: url('/img/site/pijl-links-wit.svg');
                    background-repeat: no-repeat;
                    background-position: center left;
                }
                ul {
                    display: block;
                }
            }
        }
    }
}
