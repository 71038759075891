.diensten {
    &__icon {
        width: 60px;
        height: 60px;
        background-size: 50% 50%;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 2;
        position: relative;
    }
    &__box {
        &:nth-child(1) {
            .diensten {
                &__header {
                    background: url('/img/site/teamwork-vuisten.jpg');
                }
                &__icon {
                    background-image: url('/img/site/icons/rakket-icon/color4.svg');
                    &:hover {
                        background-image: url('/img/site/icons/rakket-icon/color5.svg');
                    }
                }
            }
        }
        &:nth-child(2) {
            .diensten {
                &__header {
                    background: url('/img/site/gebouw.jpg');
                }
                &__icon {
                    background-image: url('/img/site/icons/shield-icon/color4.svg');
                    &:hover {
                        background-image: url('/img/site/icons/shield-icon/color5.svg');
                    }
                }
            }
        }
        &:nth-child(3) {
            .diensten {
                &__header {
                    background: url('/img/site/gereedschap.jpg');
                }
                &__icon {
                    background-image: url('/img/site/icons/tandwiel-icon/color4.svg');
                    &:hover {
                        background-image: url('/img/site/icons/tandwiel-icon/color5.svg');
                    }
                }
            }
        }
    }
}
