.product-specs {
    table {
        tr {
            &:nth-child(odd) {
                background-color: $grey-light;
            }
            th,
            td {
                font-size: 13px;
                color: $grey-dark;
                padding: .5rem;
                width: 50%;
                line-height: 22px;
            }
        }
    }
}
