// http://chir.ag/projects/name-that-color/

// Core colours
$transparent: rgba( 255, 255, 255, 0);
$primary: #223037;
$popup-opacity: rgba( 34, 48, 55, .5);
$white-opacity: rgba( 254, 254, 254, .1);
$white: #ffffff;
$grey-opacity: rgba( 34, 34, 34, .1);
$grey-light: #f9f9f9;
$grey: #a6a8ae;
$grey-dark: #606e78;

// Greys
$gallery: #eeeeee;

// Theme Colours
$forest-green: #9cc68b;
$pink: #5b108b;
$purple: #ae2a71;
$palm-green: #0d270a;
$yellow: #ffbf1f;
$red: #9f2323;
$navy-blue-invisible: rgba(27, 45, 60, .0);
$navy-blue: #1b2d3c;
$blue: #3e4b51;
$light-blue: #f8fbfd;
$light-blue-x: #f3f4f8;
$orange: #ff9b38;
$verdino-blue: #014289;
$green: #539a35;
$green-dark: #4a8730;
$dark-green: #9cc68b;


// New Site CASES colour palette
$cases-black: #171717;
$cases-orange: #FCB500;
$cases-grey: #F5F5F5;
$cases-border: #EBEBEB;


// Variables
$colour-primary: $red;
$colour-accent: $pink;
$colour-secondary: $orange;
$colour-fourth: $yellow;

//Variables darkened
$colour-primary-darkened: darken($colour-primary, 10%);
$colour-accent-darkened: darken($colour-accent, 10%);
$colour-secondary-darkened: darken($colour-secondary, 10%);


$colour-background: $white;
$colour-inner-background: $gallery;

$colour-font: $grey;

//social site colours
$twitter-blue: #55ACEE;
$whatsapp-green: #48C758;
$linkedin-blue: #0077B5;
$facebook-blue: #39619F;
$google-red: #F33F2C;

$theme-colours: (
	cases-grey: $cases-grey,
	cases-orange: $cases-orange,
	cases-border: $cases-border,
	cases-black: $cases-black,
	transparent: $transparent,
	primary: $primary,
	popup-opacity: $popup-opacity,
	white-opacity: $white-opacity,
	white: $white,
	grey-opacity: $grey-opacity,
	grey-light: $grey-light,
	grey: $grey,
	grey-dark: $grey-dark,
	colour-primary: $colour-primary,
	colour-accent: $colour-accent,
	colour-secondary: $colour-secondary,
	colour-primary-darkened: $colour-primary-darkened,
	colour-accent-darkened: $colour-accent-darkened,
	colour-secondary-darkened: $colour-secondary-darkened,
	colour-background: $colour-background,
	colour-inner-background: $colour-inner-background,
	colour-font: $colour-font,
	forest-green: $forest-green,
	dark-green: $forest-green,
	green: $green,
	green-dark: $green-dark,
	pink: $pink,
	orange: $orange,
	yellow: $yellow,
	red: $red,
	navy-blue-invisible: $navy-blue-invisible,
	navy-blue: $navy-blue,
	blue: $blue,
	light-blue: $light-blue,
	light-blue-x: $light-blue-x,
	gallery: $gallery,
	palm-green: $palm-green,
	verdino-blue: $verdino-blue,
	whatsapp-green: $whatsapp-green,
	linkedin-blue: $linkedin-blue,
	twitter-blue: $twitter-blue
);

