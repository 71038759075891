.werkwijze {
    &:after {
        content: '';
        display: block;
        background-image: url('/img/site/border-bottom.png');
        position: absolute;
        background-position: center bottom;
        bottom: 31px;
        width: 100vw;
        height: 10px;
    }
    &__header {
        height: calc(80vw - 32px);
    }
    &__icon {
        width: 71px;
        height: 71px;
        img {
            width: 50%;
            margin-left: 25%;
            transform: translateY(-50%);
            margin-top: 50%;
        }
    }
    &__volgorde {
        a {
            font-size: 12px;
            img {
                height: 30px;
                max-width: 30px;
            }
        }
        .volgorde {
            &__stap {
                .stap-icon {
                    width: 60px;
                    height: 60px;
                    background-size: 50%;
                }
                &.slick-current {
                    &:nth-child(1) .stap-icon {
                        background-image: url('/img/site/icons/onderzoeken-icon/color5.svg');
                    }
                    &:nth-child(2) .stap-icon {
                        background-image: url('/img/site/icons/organiseren-icon/color5.svg');
                    }
                    &:nth-child(3) .stap-icon {
                        background-image: url('/img/site/icons/ontwikkelen-icon/color5.svg');
                    }
                    &:nth-child(4) .stap-icon {
                        background-image: url('/img/site/icons/optimaliseren-icon/color5.svg');
                    }
                }
                &:nth-child(1) {
                    .stap-icon {
                        background-image: url('/img/site/icons/onderzoeken-icon/color4.svg');
                        &:hover {
                            background-image: url('/img/site/icons/onderzoeken-icon/color5.svg');
                        }
                    }
                }
                &:nth-child(2) {
                    .stap-icon {
                        background-image: url('/img/site/icons/organiseren-icon/color4.svg');
                        &:hover {
                            background-image: url('/img/site/icons/organiseren-icon/color5.svg');
                        }
                    }
                }
                &:nth-child(3) {
                    .stap-icon {
                        background-image: url('/img/site/icons/ontwikkelen-icon/color4.svg');
                        &:hover {
                            background-image: url('/img/site/icons/ontwikkelen-icon/color5.svg');
                        }
                    }
                }
                &:nth-child(4) {
                    .stap-icon {
                        background-image: url('/img/site/icons/optimaliseren-icon/color4.svg');
                        &:hover {
                            background-image: url('/img/site/icons/optimaliseren-icon/color5.svg');
                        }
                    }
                }
            }
        }
    }
    .button {
        padding: 0;
        &:after {
            margin-left: 0;
        }
    }
}

.slider-button-holder {
    z-index: 1;
}

@media screen and (min-width: $medium) {
    .werkwijze {
        padding-top: 80px;
        &:after {
            background-size: 100% 10px;
        }
        &__volgorde {
            background-image: url('/img/site/striped-line.svg');
            a {
                font-size: 15px;
            }
        }
        &__header {
            height: 105% !important;
        }
        .volgorde {
            &__stap {
                margin-bottom: 40px;
                .stap-icon {
                    width: 100px;
                    height: 100px;
                    background-size: 30px;
                    transform: translateY(25%);
                }
            }
        }
    }
    .diensten {
        &__container {
            position: initial !important;
            .werkwijze {
                &__box {
                    padding-top: 136px;
                    padding-bottom: 136px;
                }
            }
        }
    }
}
