.steps-block {
    .active {
        h5 {
            font-weight: 700;
        }
    }
}

.aanpassen {
    position: absolute;
    right: 0;
    top: 0;
    color: #f9f9f9;
    background: #cecece;
    padding: 0 7px;
    font-size: 12px;
}
