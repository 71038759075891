.cases {
    &__box {
        margin-bottom: 24px;
        .box {
            &__inner {
                background-image: url('/img/site/icons/pijltje/rechts/color4.svg');
                background-position: top 21.5px right 16px;
            }
        }
        .box {
            &__header {
                height: 220px;
                @media screen and (min-width: $medium) {
                    height: 400px;
                }
                width: calc(100% + 32px);
            }
        }
        p {
            font-size: 12px;
            max-height: 67px;
        }
    }
}

@media screen and (min-width: $medium) {
    .cases {
        &__box {
            margin-bottom: 40px;
            .box {
                &__inner {
                    background-position: center right 16px;
                    transition: .5s;
                    &:hover {
                        background-position: center right;
                    }
                }
            }
            p {
                font-size: 12px;
                max-height: 100%;
            }
        }
    }
}
