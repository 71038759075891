html, body {
    height: 100%;
    width: 100%;
    font-size: $base-font-size;
    overflow-x: hidden;
}

body {
    font-family: $base-font-family;
    color: $colour-font;
    height: auto;
}

.scrollBar {
    &::-webkit-scrollbar {
        width: 1px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f3f3f3;
    }

    &::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 10px;
    }
}

// Contain images and iframe to their parent, rarely do we want them escaping their parent.
img, iframe {
    max-width: 100%;
}

// This gets around width and height attributes on images.
img {
    height: auto;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.scheidslijn {
    border-top: 1px solid $grey-opacity;
}

.main-content {
    &.contact {
        iframe {
            height: 200px;
        }
        >.container {
            &:first-child {
                padding: 0 16px;
            }
        }
    }
}
.main-content {
    >.container {
        &:first-child {
            padding-top: 2rem;
        }
    }
}
@media screen and (min-width: $medium) {
    .main-content {
        &.contact {
            iframe {
                height: 449px;
            }
            >.container {
                &:first-child {
                    padding: 0 0 40px 0;
                }
            }
        }
    }
    .main-content {
        >.container {
            &:first-child {
                padding: 80px 0;
            }
        }
    }
}
