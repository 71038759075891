.table {
    * {
        line-height: 22px;
        font-size: 14px;
        font-weight: 300;
    }
    &__bestelling {
        padding-bottom: 16px;
        border-bottom: 1px solid $grey-opacity;
        margin-bottom: 16px;
        .bestelling {
            &__totaal {
                padding-bottom: 16px;
                border-bottom: 1px solid $grey-opacity;
                margin-bottom: 16px;
            }
            &__inner {
                background-image: url('/img/site/pijl-rechts-grey-dark.svg');
                background-position: center right;
                &.open {
                    background-image: url('/img/site/pijl-beneden-grey-dark.svg');
                }
            }
            &__producten {
                display: none;
            }
        }
        &--child {
            padding-bottom: 16px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    .bestelling {
        &__totaal {
            padding-bottom: 16px;
            border-bottom: 1px solid $grey-opacity;
            margin-bottom: 16px;
            &--afrekenen {
                width: 100%;
            }
            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
    &__row {
        width: 100%;
    }
    &__column {
        width: 50%;
    }
    &__row,
    &__column {
        display: inline-block;
        span {
            font-size: 12px;
        }
        &--empty {
            max-height: 22px;
            height: 22px;
            width: 100%;
        }
        &.font-bold {
            color: #223037;
            font-size: 13px;

        }
    }
}

.vraag {
    background-image: url("/img/site/plus.svg");
    background-position: top 1rem right 1rem;
    &.open {
        background-image: url("/img/site/min.svg");
        background-position: top 19px right 1rem;
    }
    .antwoord {
        display: none;
    }
}

.filter-box {
    display: none;
    .filters {
        background-image: url("/img/site/pijl-beneden-grey-dark.svg");
        background-position: top 23.5px right 1rem;
        &.open {
            background-image: url("/img/site/pijl-rechts-grey-dark.svg");
        }
        &__opties {
            display: none;
        }
    }
}

.producten-wrapper {
    &.closed {
        display: none;
    }
}

.popup {
    min-height: 150vh;
    padding: 100px 0;
    display: none;
    &.open {
        display: flex;
    }
}

@media screen and (min-width: $medium) {
    .table {
        width: 100%;
        * {
            font-size: 13px;
            line-height: 23px;
        }
        &__row {
            width: 80%;
            form {
                margin: 0;
            }
            .table {
                &__column {
                    width: 25%;
                    .table {
                        &__row {
                            width: 33.333333%;
                        }
                    }
                    &:last-child {
                        width: 74%;
                    }
                }
            }
            .title {
                font-size: 13px;
            }
        }
        .bestelling {
            &__totaal {
                &--afrekenen {
                    width: auto;
                }
            }
        }
    }
}
