footer {
    .social-media {
        img {
            height: 16px;
            width: auto;
        }
    }
    a {
        font-size: 13px;
        line-height: 23px;
    }
    p a {
        color: #606E78;
        font-size: 13px;
        line-height: 31px;
    }
}

