.accessoires {
    p {
        font-size: 15px;
        line-height: 27px;
        color: #606E78;
    }
    &__content {
        height: auto;
        background: #ffffff;
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;

        &--left {
            width: 100%;
            flex: 0 0 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            img {
                max-height: 112px;
            }
        }
        &--right {
            width: 100%;
            flex: 0 0 100%;
            text-align: center;
        }
    }
    @media screen and (min-width: $large) {
        &__content {
            height: auto;

            &--left {
                width: 50%;
                flex: 0 0 50%;

                img {
                    max-height: 112px;
                }
            }
            &--right {
                width: 50%;
                flex: 0 0 50%;
                text-align: center;
                padding: 10px;
            }
        }
    }
}

.meebestellen {
    &__message {
        position: absolute;
        opacity: 0;
        top: -32px;
        left: 0;
        background: #539b2f;
        color: #ffffff;
        width: 100%;
        padding: 7px;
    }
}


