.info-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    transition: ease-out 0.2s;
    cursor: pointer;

    &--content {
        display: none;
        z-index: 5;
        position: relative;
    }
    &:hover &--content {
        display: block;
    }
    &:hover {
        padding: 10px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
        background: #ffffff;
    }
}
