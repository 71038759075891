.cookies {
    .button {
        display: inline-block;
        width: auto;
        &:first-child {
            padding-left: 0;
        }
    }
}
@media screen and (min-width: $medium) {
    .cookies {
        padding: 40px 80px;
    }
}
