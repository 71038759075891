.container {
    &__background {
        background-size: cover;
        background-position: center center;
        &--overfloat {
            margin-bottom: -40px;
            z-index: 50;
            position: relative;
        }
    }
}

.bericht {
    &__header {
        height: 160px;
        overflow: hidden;
        width: calc(100% + 32px);
        text-align: center;
        @media screen and (min-width: $medium) {
            height: 210px;
        }

    }
}

.overfloat {
    &--helper {
        padding-top: 72px;
    }
}
@media screen and (min-width: $medium) {
    .home {
        .container {
            &__background {
                width: 100%;
                height: 100%;
                order: 2;
                &--overfloat {
                    margin-bottom: 0;
                    height: auto;
                }
            }
        }
    }
    .content-header {
        &__content {
            padding-top: 244px !important;
        }
    }
    .container {
        &__background {
            height: calc(100% - 80px);
            &--overfloat {
                margin-bottom: -164px;
                height: 610px;
            }
        }
    }
    .matrix {
        .container__background {
            height: 280px;
        }
    }
    .overfloat {
        &--helper {
            padding-top: 0;
        }
    }
    .product-overzicht {
        &__header {
            padding-bottom: 67px;
        }
    }
}
@media screen and (max-width: $medium) {
    .container {
        &__background {
            height: 200px;
            width: calc(100vw - 1rem) !important;
        }
    }
}
