.button {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 10px;
    background-color: $primary;
    padding: 4.5px 24px;
    color: $white;
    font-weight: 300;
    display: inline-block;
    &--cart {
        background-color: #223037;
        color: $white;
        text-align: center;

        &:after {
            content:"";
            background: url("/img/site/voeg-toe-aan-winkelwagen-icon.svg");
            width: 33px;
            height: 17px;
            position: relative;
            margin: auto;
            display: block;
        }
    }
    &--outline {
        border: 2px solid $grey-opacity;
        padding: 2.5px 24px;
        background-color: transparent;
        color: $primary;
        transition: all .2s;
        font-weight: 500;
        &.transparent {
            border-color: $white-opacity;
            color: $white;
        }

        &:hover {
            border: 2px solid $grey;
        }
    }
    &--outline-rounded {
        border: 2px solid $grey-opacity;
        padding: 5px 22px;
        border-radius: 20px;
        background-color: transparent;
        color: $primary;
        font-size: 12px;
        transition: all .2s;
        display: block;
        &.transparent {
            border-color: $white-opacity;
            color: $white;
        }
        &.inline-block {
            display: inline-block;
        }

        &:hover {
            border: 2px solid $grey;
        }
    }
    &--next,
    &--prev {
        padding: 20px 20px;
        display: inline-block;
        background-color: $primary;
        background-repeat: no-repeat;
        background-position: center;
    }
    &--prev {
        background-image: url('/img/site/pijl-links-wit.svg');
    }
    &--next {
        background-image: url('/img/site/pijl-rechts-wit.svg');
    }
    &--green {
        background-color: $green;
    }
    &--green-arrow {
        background-color: $green;
        &:after {
            content: '';
            width: 40px;
            margin-left: 16px;
            display: none;
            background-image: url('/img/site/pijltje-rechts-wit.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-color: $green-dark;
        }
    }
}

.text-button {
    background-color: transparent;
    color: $primary;
    padding-left: 0;
    padding-right: 0;
    &--white {
        color: $white;
    }
}

.play-icon {
    height: 42px;
    width: 42px;
    background-image: url('/img/site/pijl-rechts-wit.svg');
    background-size: 13px 17px;
    display: none;
}

@media screen and (min-width: $medium) {
    .button {
        padding: 6.5px 32px;
        font-size: 12px;
        line-height: 27px;
        &--outline {
            padding: 4.5px 32px;
        }
        &--outline-rounded {
            border: 2px solid $grey-opacity;
            padding: 5px 22px;
            border-radius: 20px;
            background-color: transparent;
            color: $primary;
            font-size: 12px;
            transition: all .2s;
            display: block;
            &.transparent {
                border-color: $white-opacity;
                color: $white;
            }
            &.inline-block {
                display: inline-block;
            }

            &:hover {
                border: 2px solid $grey;
            }
        }
        &--green {
            padding: 6.5px 32px;
        }
        &--green-arrow {
            position: relative;
            padding-right: 80px;
            &:after {
                position: absolute;
                right: -20px;
                top: 0;
                bottom: 0;
                display: inline-block;
            }
        }
        &.filter {
            display: none;
        }
    }
    .text-button {
        padding: 0 21px 0 0;
        &__arrow {
            background-image: url('/img/site/pijltje-rechts-primary.svg');
            background-repeat: no-repeat;
            background-position: center right;
            &--white {
                background-image: url('/img/site/pijltje-rechts-wit.svg');
                background-repeat: no-repeat;
                background-position: center right;
            }
        }
    }
    .play-icon {
        height: 74px;
        width: 74px;
    }
}

.betaalmethodes {
    img {
        max-width: 53px;
        max-height: 39px;
    }
}

.kleuren {
    .active {
        background-image: url("/img/site/chosen-color.svg");
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.route {
    order: 3;
}

.filter {
    &.hide {
        display: none;
    }
}
