form {
    margin-top: 1rem;
    input,
    textarea {
        border-bottom: 1px solid $primary;
        display: block;
        width: 100%;
        padding-bottom: .5rem;
        margin-bottom: 1.5rem;
        font-size: 13px;
        color: $grey-dark;
        &::placeholder {
            color: $grey;
            opacity: 1;
        }
        &:focus {
            outline: none;
        }
    }
    select {
        background-color: $white;
        background-image: url("/img/site/pijl-beneden-grey-dark.svg");
        background-repeat: no-repeat;
        background-position: top 8px right;
        font-size: 13px;
        padding-bottom: .5rem;
        margin-bottom: 1.5rem;
        display: block;
        width: 100%;
        border-bottom: 1px solid $primary;
        color: $grey-dark;
    }
    textarea {
        margin-bottom: 1rem;
    }
    button {
        &.button {
            margin-top: 0;
        }
    }
    button,
    input,
    textarea {
        line-height: 23px;
    }
    .numbers-row {
        .inc,
        .dec {
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            padding: 0;
            text-align: center;
            line-height: 16px;
            margin: 0 8px;
        }
        .dec {
            margin-right: 5px;
        }
        input {
            width: 32px;
            height: 28px;
            border: 2px solid $grey-light;
            line-height: 28px;
            padding: 0;
            margin: 0;
            text-align: center;
        }
    }
    /* The container */
    label {
        display: block;
        position: relative;
        padding-left: 24px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $white;
        border: 2px solid $grey-dark;
    }

    /* On mouse-over, add a grey background color */
    label:hover input ~ .checkmark {
        background-color: $white;
    }

    /* When the radio button is checked, add a blue background */
    label input:checked ~ .checkmark {
        background-color: $white;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    label input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    label .checkmark:after {
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        background: $grey-dark;
    }
}

.gform_button {
    &.button {
        width: auto;
        padding: 0px 40px;
        font-size: 15px;
        font-weight: bold;
        color: $white;
        background: $colour-font;
        text-transform: uppercase;
        font-family: 'Quicksand';
        transition: all 0.3s ease;
        &:hover {
            background-color: $colour-primary;
            border-color: $colour-primary;
        }
    }
}

input[type="radio"]:checked + label {
    box-shadow: 1px 1px 2px 1px rgba(41, 43, 44, 0.79);
}

input[type="radio"]:checked + label:after {
    content: 'icon';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}



.ginput_recaptcha {
    display: inline-block !important;
    float: left;
}

.gfield {
    position: relative;
}

.validation_error {
    margin-bottom: 20px;
}

.gfield_error {
    input {
        border: 1px solid red;
    }
}

select {
    &.disabled {
        opacity: 0.3;
    }
}
@media screen and (min-width: $medium) {
    form {
        textarea {
            margin-bottom: 1.5rem;
        }
        textarea,
        input {
            font-size: 15px;
        }
        button {
            &.button {
                padding: 8.5px 32px;
            }
        }
    }
}

input#likkepot { display: none; }
