.share-box {
    &__share-icon {
        width: 100%;
        height: 56px;
        span {
            padding: 16px 0;
        }
        &--whatsapp {
            background-image: url('/img/site/icons/whatsapp-icon/color1.svg');
        }
        &--twitter {
            background-image: url('/img/site/icons/twitter-icon/color1.svg');
        }
        &--linkedin {
            background-image: url('/img/site/icons/linkedin-icon/color1.svg');
        }
    }
}
@media screen and (min-width: $medium) {
    .share-box {
    &__share-icon {
        width: 100%;
        height: 56px;
        &--whatsapp {
            background-image: none;
        }
        &--twitter {
            background-image: none;
        }
        &--linkedin {
            background-image: none;
        }
    }
}
}
