.bericht {
    p {

    }
}
@media screen and (min-width: $medium) {
    .bericht {
        p {
            font-size: 13px;
        }
    }
}
